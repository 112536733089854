import Table from "./Table";
import "./App.css";

let data = [];

const rows = [{ name: "foo " }];
function App() {
  return (
    <div className="App">
      <Table />
    </div>
  );
}

export default App;
