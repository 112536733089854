import react, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
} from "@material-ui/core";
import { sortBy } from "lodash";

const TableComponent = () => {
  const [data, setData] = useState([]);
  const [sortLabel, setSortLabel] = useState();

  useEffect(() => {
    fetch(
      "https://h8zmfcg5e9.execute-api.us-west-2.amazonaws.com/prd"
    ).then((response) => response.json().then((data) => setData(data)));
  }, [setData]);

  const sort = (sortLabel, sortDirection) => {
    setSortLabel("sortLabel");
    setData(sortBy(data, [sortLabel]));
  };

  return (
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell sortDirection="desc">
            <TableSortLabel
              active={sortLabel === "title"}
              direction={"asc"}
              onClick={() => sort("title")}
            >
              Title
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortLabel === "vintage"}
              direction={"desc"}
              onClick={() => sort("vintage")}
            >
              Vintage
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortLabel === "price"}
              direction={"desc"}
              onClick={() => sort("price")}
            >
              Current bid
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortLabel === "endDate"}
              direction={"desc"}
              onClick={() => sort("endDate")}
            >
              End Date
            </TableSortLabel>
          </TableCell>
          <TableCell>Links</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row) => (
          <TableRow key={`${row.title}-${row.vintage}`}>
            <TableCell component="th" scope="row">
              <a target="_blank" href={row.titleLink}>
                {row.title}
              </a>
            </TableCell>
            <TableCell>{row.vintage}</TableCell>
            <TableCell>{row.price}</TableCell>
            <TableCell>{row.endDate}</TableCell>
            <TableCell component="th" scope="row">
              <a
                target="_blank"
                href={`https://www.wine-searcher.com/find/${encodeURIComponent(
                  `${row.vintage}+${row.title.split(" ").join("+")}`
                )}`}
              >
                wine searcher
              </a>
              ,{" "}
              <a
                target="_blank"
                href={`https://www.cellartracker.com/list.asp?fInStock=0&Table=List&iUserOverride=0&szSearch=${encodeURIComponent(
                  `${row.vintage} ${row.title}`
                )}`}
              >
                cellar tracker
              </a>
              ,{" "}
              <a
                target="_blank"
                href={`https://www.vivino.com/search/wines?q=${encodeURIComponent(
                  `${row.vintage}+${row.title.split(" ").join("+")}`
                )}`}
              >
                vivino
              </a>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TableComponent;
